<template>
  <LoadingState v-if="isLoading" />
  <div class="resultTeacher" v-else>
    <a-modal
      class="modal-notes"
      :visible="confirmEditGrade"
      title="Add Changing Notes"
      :width="900"
      centered
      @ok="editGradeCBTByAttendance"
      @cancel="confirmEditGrade = false"
      okText="Edit"
    >
      <a-table
        :columns="columnEdit"
        :data-source="dataTableSavedChanges"
        :pagination="false"
      >
        <a-input
          :default-value="record.notes"
          slot="notes"
          slot-scope="text, record"
          @change="(e) => changeEditCbtNotes(e, record)"
        />
      </a-table>
    </a-modal>
    <div class="coverDetail">
      <h2>
        {{ detailCbt.tipe }} {{ detailCbt.tingkat }} | {{ detailCbt.mapel }}
      </h2>
      <div>{{ waktuUjian.str }} | {{ detailCbt.tipe_cbt }} {{detailCbt.id_kd ? '| KD-' + detailCbt.kd_master.no : null}}</div>
    </div>
    <div class="contentDetail">
      <div class="detail-slot">
        <a-row>
          <a-col :span="24">
            <div class="d-lg-flex flex-wrap mb-3">
              <a-select
                :class="['d-block', !isDesktop ? 'mb-3' : '']"
                size="large"
                ref="selectClass"
                placeholder="Select Class"
                :style="{
                  width: isDesktop ? '140px' : '',
                  height: isDesktop ? '48px' : '',
                }"
                default-value="All Class"
                @change="handleSelectClass"
              >
                <a-select-option value>All Class</a-select-option>
                <a-select-option
                  v-for="item in classes"
                  :key="item.id"
                  :value="item.id"
                  >{{ item.level.nama }}-{{ item.simbol }}</a-select-option
                >
              </a-select>
              <a-form-item class="d-block d-lg-none">
                <a-select
                  @change="(e) => (selectView = e)"
                  :value="selectView"
                  :disabled="isEditGrade"
                  size="large"
                  style="width: 100%; height: 40px"
                >
                  <a-select-option value="Grade">{{
                    textRadio1
                  }}</a-select-option>
                  <a-select-option value="Analytics">{{
                    textRadio2
                  }}</a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item class="d-none d-lg-block">
                <a-radio-group
                  default-value="Grade"
                  button-style="solid"
                  size="large"
                  style="height: 48px"
                  class="ml-2"
                  @change="handleSelectView"
                  :disabled="isEditGrade"
                >
                  <a-radio-button value="Grade">{{
                    textRadio1
                  }}</a-radio-button>
                  <a-radio-button value="Analytics">{{
                    textRadio2
                  }}</a-radio-button>
                </a-radio-group>
              </a-form-item>
              <a-dropdown>
                <a-menu slot="overlay" @click="handleMenuClick">
                  <a-menu-item class="p-3 font-size-16" key="1"
                    ><a-icon style="font-size: 16px" type="file-pdf" />Daftar
                    Hadir
                  </a-menu-item>
                  <a-menu-item
                    class="p-3 font-size-16"
                    :disabled="!dataTable.length || disableExport"
                    key="2"
                  >
                    <a-icon style="font-size: 16px" type="file-pdf" />Berita
                    Acara
                  </a-menu-item>
                  <a-menu-item
                    class="p-3 font-size-16"
                    :disabled="!dataTable.length || disableExport"
                    key="3"
                  >
                    <a-icon type="file-excel" style="font-size: 16px" />Daftar
                    Nilai
                  </a-menu-item>
                </a-menu>
                <a-button
                  type="primary"
                  size="large"
                  :block="!isDesktop"
                  class="ml-lg-2"
                >
                  Generate <a-icon type="down" />
                </a-button>
              </a-dropdown>
              <!-- <a-button
                type="default"
                size="large"
                class="ml-lg-2"
                icon="file-excel"
                :block="!isDesktop"
                :style="{
                  'margin-top': isDesktop ? '' : '10px',
                }"
                :disabled="!dataTable.length || disableExport"
                @click.prevent="exportToXslx"
                >Daftar Nilai</a-button
              >
              <a-button
                type="default"
                size="large"
                class="ml-lg-2"
                icon="file-pdf"
                :block="!isDesktop"
                :style="{
                  'margin-top': isDesktop ? '' : '10px',
                }"
                :disabled="!dataTable.length || disableExport"
                @click.prevent="exportBeritaAcara"
                >Berita Acara</a-button
              > -->
              <a-button
                v-if="!isEditGrade"
                @click.prevent="editGrade"
                :loading="loadingEditCbt"
                class="ml-lg-auto font-size-16"
                type="default"
                :style="{
                  'margin-top': isDesktop ? '' : '10px',
                  height: '40px',
                }"
                :block="!isDesktop"
              >
                <a-icon type="form" />Edit Grade
              </a-button>
              <template v-else>
                <a-button
                  @click.prevent="isEditGrade = !isEditGrade"
                  :loading="loadingEditCbt"
                  type="danger"
                  icon="close"
                  size="large"
                  class="ml-lg-auto"
                  :block="!isDesktop"
                  :style="{
                    'margin-top': isDesktop ? '' : '10px',
                    height: '40px',
                  }"
                  >CANCEL</a-button
                >
                <a-button
                  @click.prevent="saveCbtGrade()"
                  class="ml-lg-2"
                  icon="save"
                  :type="!savedChangedTotal.length ? 'default' : null"
                  size="large"
                  :disabled="!savedChangedTotal.length"
                  :style="{
                    'margin-top': isDesktop ? '' : '10px',
                    height: '40px',
                    'background-color': !savedChangedTotal.length
                      ? null
                      : '#41b883',
                    color: !savedChangedTotal.length ? null : 'white',
                  }"
                  :block="!isDesktop"
                  >SAVE</a-button
                >
              </template>
              <a-button
                @click.prevent="refreshTable"
                class="ml-lg-2 font-size-16"
                type="default"
                :style="{
                  'margin-top': isDesktop ? '' : '10px',
                  height: '40px',
                }"
                :block="!isDesktop"
                :disabled="isEditGrade"
              >
                <a-icon type="sync" :spin="loadingRefresh" />Refresh
              </a-button>
            </div>
            <a-drawer
              width="500"
              placement="left"
              :closable="true"
              :visible="visibleDrawer"
              @close="onCloseDrawer"
              title="Computer Based Test Result"
            >
              <!-- {{dataDrawer.EssayAnswer.length}} -->
              <p>NIS : {{ dataDrawer.nis }}</p>
              <a-divider />
              <p>Name : {{ dataDrawer.Name }}</p>
              <a-divider />
              <p>Class : {{ dataDrawer.Class }}</p>
              <a-divider />
              <p>Essay Grade : {{ dataDrawer.EssayGrade }}</p>
              <a-divider />
              <div class="d-flex flex-nowrap mb-5">
                <h3 class="font-weight-bold text-dark my-auto">
                  Essay Answer :
                </h3>
                <a-button
                  v-if="!dataDrawer.EssayGrade && statusSlot"
                  class="ml-auto"
                  @click.prevent="updateGrade(dataDrawer.key)"
                  size="large"
                  type="primary"
                  :loading="loadingUpdateGrade"
                  >Submit Grade</a-button
                >
              </div>
              <div v-for="(item, index) in dataDrawer.Soal" :key="index">
                <a-row :gutter="16">
                  <a-col :span="2">
                    <h3>{{ numberQuestion(index) }}.</h3>
                  </a-col>
                  <a-col :span="22">
                    <p class="mb-2" ref="soal" v-html="item.soal"></p>
                    <div
                      v-if="dataDrawer.MultipleChoiceAnswer"
                      class="studentAnswer"
                    >
                      <div>
                        <p class="font-weight-bold">Answer :</p>
                        <span
                          ref="jawaban"
                          v-html="
                            answer(item.id, dataDrawer.MultipleChoiceAnswer)
                          "
                        ></span>
                      </div>
                      <div v-if="!dataDrawer.EssayGrade && statusSlot">
                        <hr />
                        <div
                          v-show="item.tipe_soal === 'Essay'"
                          class="gradeEssay mt-1"
                        >
                          <p class="font-weight-bold">
                            Grade (0 s/d {{ item.bobot_soal }}) :
                            <span
                              :class="
                                handleGradeIndex(item.id) === 0
                                  ? 'text-danger'
                                  : ''
                              "
                              >{{ handleGradeIndex(item.id) }}</span
                            >
                          </p>
                          <a-row :gutter="16">
                            <a-col :span="18">
                              <a-slider
                                :value="handleGradeIndex(item.id)"
                                @change="handleSlider($event, item.id)"
                                :min="0"
                                :max="parseInt(item.bobot_soal)"
                                :step="parseInt(item.bobot_soal) / 10"
                              />
                            </a-col>
                            <a-col :span="4" class="mt-1">
                              <a-input-number
                                :value="handleGradeIndex(item.id)"
                                :min="0"
                                :max="parseInt(item.bobot_soal)"
                                :step="parseInt(item.bobot_soal) / 10"
                                @change="handleSlider($event, item.id)"
                              />
                            </a-col>
                          </a-row>
                        </div>
                      </div>
                    </div>
                  </a-col>
                </a-row>
                <hr />
              </div>
            </a-drawer>
            <a-table
              class="tableGradeCbt"
              v-if="selectView === 'Grade'"
              :columns="
                detailCbt.tipe_cbt === 'Multiple Choice'
                  ? columnsMultipleChoiceGrade
                  : columnsMultipleChoiceEssayGrade
              "
              :data-source="dataTable"
              :loading="loadingTable"
              :pagination="pagination"
              @change="handleTable"
              bordered
            >
              <div slot="nullChecker" slot-scope="text">
                <span v-if="text == null">-</span>
                <span v-else>{{ text }}</span>
              </div>
              <a
                slot="Name"
                class="text-primary"
                slot-scope="text, record"
                @click.prevent="showDrawer(record.key)"
                >{{ text }}</a
              >
              <div
                v-if="isEditGrade"
                slot="FinalGrade"
                slot-scope="text, record"
              >
                <a-input-number
                  :min="0"
                  :max="100"
                  :default-value="text"
                  @change="(e) => handleChangeTotalValue(e, record, text)"
                />
              </div>
              <span v-else slot="FinalGrade" slot-scope="text">
                <div v-if="text !== null">
                  <div v-if="text >= 70 && text <= 100">
                    <a-tag color="green">{{ text }}</a-tag>
                  </div>
                  <div v-else-if="text >= 35 && text < 70">
                    <a-tag color="orange">{{ text }}</a-tag>
                  </div>
                  <div v-else-if="text >= 0 && text < 35">
                    <a-tag color="red">{{ text }}</a-tag>
                  </div>
                </div>
                <div v-else>
                  <a-tag>-</a-tag>
                </div>
              </span>
              <span slot="TotalCbt" slot-scope="text, record">
                <div v-if="record.MultipleChoiceGrade !== null || record.EssayGrade !== null">
                  <div v-if="text >= 70 && text <= 100">
                    <a-tag color="green">{{ text }}</a-tag>
                  </div>
                  <div v-else-if="text >= 35 && text < 70">
                    <a-tag color="orange">{{ text }}</a-tag>
                  </div>
                  <div v-else-if="text >= 0 && text < 35">
                    <a-tag color="red">{{ text }}</a-tag>
                  </div>
                </div>
                <div v-else>
                  <a-tag>-</a-tag>
                </div>
              </span>
              <span slot="customTitle"> <a-icon type="smile-o" />Name </span>
              <span slot="Attendance" slot-scope="text">
                <p :class="text === 'absent' ? 'text-danger' : 'text-success'">
                  {{ text.toUpperCase() }}
                </p>
              </span>
              <div slot="remedial" slot-scope="value">
                <a-checkbox
                  :checked="value"
                  :disabled="!isEditGrade"
                >
                </a-checkbox>
              </div>
            </a-table>
            <a-table
              class="multipleChoiceAnalysis"
              v-if="selectView === 'Analytics'"
              :columns="columnsAnalytics"
              :data-source="dataTableAnalytics"
              :pagination="pagination"
              @change="handleTable"
              :loading="loadingTable"
              :scroll="{ x: 50, y: 500 }"
              size="small"
              bordered
            >
              <a-icon slot="filterIcon" type="search" />
              <!-- <span slot="Name" slot-scope="text, record">{{text}} {{record.Filled}}</span> -->
              <span slot="Soal" slot-scope="text">
                <a-tooltip>
                  <template slot="title">{{
                    text.charAt(2) === "C"
                      ? "CORRECT"
                      : text.charAt(2) === "W"
                      ? "WRONG"
                      : "Have not taken the exam yet"
                  }}</template>
                  <a-tag
                    :color="
                      text.charAt(2) === 'C'
                        ? 'green'
                        : text.charAt(2) === 'W'
                        ? 'volcano'
                        : ''
                    "
                    >{{ text.charAt(0) === "H" ? "-" : text.charAt(0) }}</a-tag
                  >
                </a-tooltip>
              </span>
              <!-- <template slot="footer" slot-scope="data">{{data}}</template> -->
            </a-table>
          </a-col>
        </a-row>
      </div>
    </div>
    <div>
      <div v-if="showPrint" :class="isPrint ? ['invisible', 'position-absolute'] : 'd-none' ">
        <div id="beritaAcaraDoc" class="w-100">
          <BeritaAcaraDoc
            :isPrint="isPrint"
            :dataPrint="dataPrint"
            :format="{ width: '750px' }"
            @printDone="printDone"
          />
          <!-- height 1037px -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import LoadingState from '@/components/app/LoadingState'
// import XLSX from 'xlsx'
// import XLSXStyle from 'xlsx-style'
import ExcelJs from 'exceljs'
// import { Workbook, s2ab, sheetFromArrayOfArrays } from '@/helpers/xlsxStyle'
import FileSaver from 'file-saver'
import JsPDF from 'jspdf'
import 'jspdf-autotable'
import { renderMathMl } from '@/helpers/mathjax'
// import generateBeritaAcara from '@/helpers/beritaAcara'
const BeritaAcaraDoc = () => import('@/components/app/BeritaAcaraDoc')

const columnsMultipleChoiceGrade = [
  // {
  //   title: 'NIS',
  //   dataIndex: 'nis',
  //   // width: '6%',
  //   // width: '10%',
  //   // sorter: true,
  // },
  {
    title: 'Name',
    dataIndex: 'Name',
    ellipsis: true,
    // sorter: true,
  },
  // {
  //   title: 'Class',
  //   dataIndex: 'Class',
  //   width: '4%',
  // },
  {
    title: 'Multiple Choice Grade',
    dataIndex: 'MultipleChoiceGrade',
    // width: '10%',
    scopedSlots: { customRender: 'nullChecker' },
    sorter: true,
    align: 'center',
  },
  {
    title: 'Total CBT',
    dataIndex: 'TotalCbt',
    // width: '8%',
    scopedSlots: { customRender: 'TotalCbt' },
    sorter: true,
    align: 'center',
  },
  {
    title: 'Final Grade',
    dataIndex: 'FinalGrade',
    // width: '8%',
    scopedSlots: { customRender: 'FinalGrade' },
    align: 'center',
    sorter: true,
  },
  // {
  //   title: 'Remedial',
  //   dataIndex: 'isRemedial',
  //   key: 'remedial',
  //   align: 'center',
  //   scopedSlots: { customRender: 'remedial' },
  //   width: 105,
  // },
  {
    title: 'Attendance',
    dataIndex: 'Attendance',
    // width: '5%',
    scopedSlots: { customRender: 'Attendance' },
    sorter: true,
  },
]
const columnsMultipleChoiceEssayGrade = [
  {
    title: 'NIS',
    dataIndex: 'nis',
    // width: '6%',
    // sorter: true,
  },
  {

    slots: { title: 'customTitle' },
    title: 'Name',
    dataIndex: 'Name',
    // width: '15%',
    scopedSlots: { customRender: 'Name' },
    // ellipsis: true,
    // sorter: true,
  },
  // {
  //   title: 'Class',
  //   dataIndex: 'Class',
  //   width: '4%',
  // },
  {
    title: 'Essay Grade',
    dataIndex: 'EssayGrade',
    scopedSlots: { customRender: 'nullChecker' },
    // width: '8%',
    sorter: true,
    align: 'center',
  },
  {
    title: 'Multiple Choice Grade',
    dataIndex: 'MultipleChoiceGrade',
    scopedSlots: { customRender: 'nullChecker' },
    // width: '10%',
    align: 'center',
    sorter: true,
  },
  {
    title: 'Total CBT',
    dataIndex: 'TotalCbt',
    // width: '8%',
    align: 'center',
    scopedSlots: { customRender: 'TotalCbt' },
    sorter: true,
  },
  {
    title: 'Final Grade',
    dataIndex: 'FinalGrade',
    // width: '8%',
    align: 'center',
    scopedSlots: { customRender: 'FinalGrade' },
    sorter: true,
  },
  // {
  //   title: 'Remedial',
  //   dataIndex: 'isRemedial',
  //   key: 'remedial',
  //   align: 'center',
  //   scopedSlots: { customRender: 'remedial' },
  //   width: 105,
  // },
  {
    title: 'Attendance',
    dataIndex: 'Attendance',
    // width: '10%',
    scopedSlots: { customRender: 'Attendance' },
    sorter: true,
  },
]
const columnsAnalytics = [
  // {
  //   title: 'NIS',
  //   dataIndex: 'nis',
  //   width: 98,
  //   fixed: 'left',
  // },
  {
    title: 'Name',
    dataIndex: 'Name',
    width: 230,
    fixed: 'left',
    scopedSlots: { customRender: 'Name' },
    ellipsis: true,
  },
  // {
  //   title: 'Class',
  //   dataIndex: 'Class',
  //   width: 100,
  //   fixed: 'left',
  // },
  {
    title: 'Multiple Choice Answer',
    dataIndex: 'MultipleChoiceAnswer',
    width: 400,
    scopedSlots: { customRender: 'MultipleChoiceAnswer' },
    children: '',
  },
  {
    title: 'Correct',
    dataIndex: 'Correct',
    width: 70,
    // fixed: 'right',
    align: 'center',
  },
  {
    title: 'Wrong',
    dataIndex: 'Wrong',
    width: 67,
    // fixed: 'right',
    align: 'center',
  },
]

const columnEdit = [
  {
    title: 'Name',
    dataIndex: 'nama',
    // width: 100,
    // ellipsis: true,
  },
  {
    title: 'Class',
    dataIndex: 'class',
    // width: 100,
    // ellipsis: true,
  },
  // {
  //   title: 'Class',
  //   dataIndex: 'Class',
  //   width: '4%',
  // },
  {
    title: 'Past Grade',
    dataIndex: 'old_grade',
    align: 'center',
    // width: '10%'
  },
  {
    title: 'New Grade',
    dataIndex: 'new_grade',
    align: 'center',
    // width: '8%',
  },
  {
    title: 'Notes',
    dataIndex: 'notes',
    scopedSlots: { customRender: 'notes' },
    // width: '8%',
  },
]

export default {
  data() {
    return {
      loadingEditCbt: false,
      isLoading: true,
      loadingTable: false,
      visibleDrawer: false,
      loadingUpdateGrade: false,
      loadingRefresh: false,
      statusSlot: false,
      columnsMultipleChoiceGrade,
      columnsMultipleChoiceEssayGrade,
      columnsAnalytics,
      columnEdit,
      selectView: 'Grade',
      textRadio1: 'Grade Result',
      textRadio2: 'Multiple Choice Analysis',
      gradeEssay: 0,
      dataDrawer: {},
      detailCbt: {},
      listPengawas: {},
      classes: [],
      selectedClass: '',
      pagination: {},
      order: '',
      sortBy: '',
      dataTable: [],
      dataTableAnalytics: [],
      totalMurid: '',
      kelasCBT: '',
      pgState: {
        color: '',
        answer: '',
      },
      notesBeritaAcaraPerKelas: '',
      disableExport: false,
      isEditGrade: false,
      savedChangedTotal: [],
      confirmEditGrade: false,
      dataTableSavedChanges: [],
      showPrint: false,
      isPrint: false,
      dataPrint: {},
    }
  },
  components: {
    LoadingState, BeritaAcaraDoc,
  },
  methods: {
    changeEditCbtNotes(e, record) {
      const newData = [...this.savedChangedTotal]
      const target = newData.findIndex(item => record.id_cbt_attendance === item.id_cbt_attendance)
      const value = e.target.value
      console.log(record, this.savedChangedTotal, 'ini', target)
      if (target !== -1) {
        newData[target].notes = value
        this.savedChangedTotal = newData
      }
    },
    editGradeCBTByAttendance() {
      this.$confirm({
        title: 'Warning',
        content: (
          <div>Are you sure you want to edit grade ? your data before will be lost and your changes will be saved on log.</div>
        ),
        onOk: () => {
          this.loadingEditCbt = true
          this.loadingTable = true
          this.confirmEditGrade = false
          this.isEditGrade = false
          this.$store.dispatch('cbt/EDIT_GRADE_TOTAL_CBT', { data: this.savedChangedTotal, idCbt: this.$route.params.id })
            .then(isSuccess => {
              this.loadingEditCbt = false
              if (isSuccess) {
                this.savedChangedTotal = []
                this.dataTableSavedChanges = []
                this.$notification.success({
                  message: 'Success.',
                  description: 'CBT total grade has been updated',
                })
              } else {
                this.$notification.error({
                  message: 'Sorry.',
                  description: 'Failed to update CBT grade. Please try again.',
                })
              }

              this.fetchDataCbtTable()
            })
        },
        onCancel: () => {
        },
        centered: true,
        icon: 'warning',
        okType: 'primary',
        okText: 'Yes',
      })
    },
    saveCbtGrade() {
      this.confirmEditGrade = true
      this.dataTableSavedChanges = this.savedChangedTotal.map((data, i) => {
        console.log(data)
        return {
          ...data,
          key: data.id_cbt_attendance || i,
        }
      })
    },
    handleChangeTotalValue(value = '', data, textData) {
      const newData = [...this.savedChangedTotal]
      const target = newData.findIndex(item => data.key === item.id_cbt_attendance)
      // console.log(textData, value, data, this.savedChangedTotal, 'ini', target)
      if (target !== -1) {
        newData[target] = {
          ...newData[target],
          new_grade: value,
        }
        this.savedChangedTotal = newData
      } else {
        this.savedChangedTotal.push({
          id_cbt_attendance: data.key,
          new_grade: value,
          nama: data.Name,
          old_grade: data.FinalGrade,
          notes: '',
          class: data.Class,
        })
      }
    },
    editGrade() {
      this.isEditGrade = !this.isEditGrade
      if (this.isEditGrade) {
        this.savedChangedTotal = []
      }
    },
    handleMenuClick(e) {
      if (Number(e.key) === 1) this.daftarHadirToPDF()
      else if (Number(e.key) === 2) this.exportBeritaAcara()
      else if (Number(e.key) === 3) this.exportToXlsx()
      // console.log(e)
    },
    // save() {
    //   /* original data */
    //   var data = [[1, 2, 3, 4], ['Sample', 'Sample', 'Sample', 'Sample'], ['foo', 'bar', 'Hello', '0.3'], ['baz', null, 'qux']]
    //   var wsName = 'SheetJS'

    //   var wb = new Workbook(); var ws = sheetFromArrayOfArrays(data)

    //   /* add worksheet to workbook */
    //   wb.SheetNames.push(wsName)
    //   wb.Sheets[wsName] = ws
    //   var wbout = XLSXStyle.write(wb, { bookType: 'xlsx', bookSST: true, type: 'binary' }, 'sample.xlsx')
    //   FileSaver.saveAs(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }), 'sample.xlsx')
    // },
    async exportToXlsx() {
      const wb = new ExcelJs.Workbook()
      const data = await this.$store.dispatch('cbt/FETCH_CBT_ATTENDANCES_BY_ID', {
        idCbt: this.$route.params.id,
        page: 'all',
      })
      for (let i = 0; i < data.kelasTingkat.length; i++) {
        const kelas = `${data.kelasTingkat[i].level.nama} - ${data.kelasTingkat[i].simbol}`
        const dataHeader = ['DINAS PENDIDIKAN PROVINSI DKI JAKARTA',
          `PENILAIAN COMPUTER BASED TEST (CBT) ${this.detailCbt.tipe} KELAS ${kelas} MAPEL ${this.detailCbt.mapel.toUpperCase()} SEMESTER ${this.detailCbt.tahun_ajaran.semester.toUpperCase()} TAHUN ${this.detailCbt.tahun_ajaran.tahun}`,
          'SMP AL BAYAN ISLAMIC SCHOOL']
        const ws = wb.addWorksheet(kelas)
        // console.log(col)
        // untuk merge
        // const colProp = {
        //   A: { width: 20 },
        //   B: { width: 40 },
        //   C: { width: 20 },
        //   D: { width: 25 },
        //   E: { width: 20 },
        //   F: { width: 20 },
        // }
        ws.columns = [
          { width: 20 },
          { width: 40 },
          { width: 20 },
          { width: 25 },
          { width: 20 },
          { width: 20 }]
        // for(const col in colProp) {
        //   ws.getColumn(col).width = colProp[col].width
        // }

        for (let j = 1; j <= 3; j++) {
          ws.getCell(`A${j}`).value = dataHeader[j - 1]
          ws.getCell(`A${j}`).font = { size: 12, bold: true }
          ws.getCell(`A${j}`).alignment = { horizontal: 'center' }
          ws.mergeCells(`A${j}:F${j}`)
        }

        const cols = [...Array(6)].map((arr, i) => { return String.fromCharCode(i + 65) })

        // define header
        const valueHeader = [
          { value: 'NIS', key: 'nis' },
          { value: 'Nama', key: 'nama' },
          { value: 'Nilai Essay', key: 'nilai_essay' },
          { value: 'Nilai Pilihan Ganda', key: 'nilai_pg' },
          { value: 'Total Nilai CBT', key: 'total_nilai' },
          { value: 'Nilai Final', key: 'new_nilai' },
        ]
        cols.forEach((col, idx) => {
          const headerProp = valueHeader[idx]
          const cell = ws.getRow(5).getCell(col)
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          }
          cell.value = headerProp.value
          cell.key = headerProp.key
          cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFF00' } }
          cell.alignment = { horizontal: 'center' }
        })
        //

        let counter = 0
        for (let k = 0; k < data.cbt.cbt_attendances.length; k++) {
          const muridCbtAttendance = data.cbt.cbt_attendances[k]
          const dataTable = {
            A: muridCbtAttendance.kelas_tahun_ajaran.murid.nis,
            B: muridCbtAttendance.kelas_tahun_ajaran.murid.nama,
            C: muridCbtAttendance.nilai_essay ?? '-',
            D: muridCbtAttendance.nilai_pg ?? '-',
            E: muridCbtAttendance.total_nilai !== null && (muridCbtAttendance.nilai_essay !== null || muridCbtAttendance.nilai_pg !== null) ? muridCbtAttendance.total_nilai : '-',
            F: muridCbtAttendance.new_nilai ?? '-',
          }
          if (muridCbtAttendance.kelas_tahun_ajaran.id_kelas === data.kelasTingkat[i].id) {
            for (const col in dataTable) {
              const cell = ws.getRow(counter + 6).getCell(col)
              cell.value = dataTable[col]
              cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' },
              }
              if (col !== 'A' && col !== 'B') cell.alignment = { horizontal: 'center' }
            }
            counter++
          }
        }
      }

      // data.cbt.cbt_attendances.forEach((attendance, i) => {

      // })
      const title = `Daftar Nilai CBT ${this.detailCbt.tipe} Mapel ${this.detailCbt.mapel} Semester ${this.detailCbt.tahun_ajaran.semester} Tahun ${this.detailCbt.tahun_ajaran.tahun}.xlsx`
      const buffer = await wb.xlsx.writeBuffer()
      FileSaver.saveAs(new Blob([buffer]), `${title}.xlsx`)
    },
    printDone() {
      this.showPrint = false
      this.dataPrint = {}
      this.isPrint = false
    },
    // async exportToXslx() {
    //   // const wb = XLSX.utils.book_new()
    //   const wb = XLSX.utils.book_new()
    //   const data = await this.$store.dispatch('cbt/FETCH_CBT_ATTENDANCES_BY_ID', {
    //     idCbt: this.$route.params.id,
    //     page: 'all',
    //   })
    //   // console.log(this.detailCbt)
    //   // console.log(data, 'tessssss')
    //   for (let i = 0; i < data.kelasTingkat.length; i++) {
    //     /* convert state to workbook */
    //     const kelas = `${data.kelasTingkat[i].tingkat} - ${data.kelasTingkat[i].simbol}`
    //     const dataHeader = [['DINAS PENDIDIKAN PROVINSI DKI JAKARTA'],
    //       [`PENILAIAN COMPUTER BASED TEST (CBT) ${this.detailCbt.tipe} KELAS ${kelas} MAPEL ${this.detailCbt.mapel.toUpperCase()} SEMESTER ${this.detailCbt.tahun_ajaran.semester.toUpperCase()} TAHUN ${this.detailCbt.tahun_ajaran.tahun}`],
    //       ['SMP AL BAYAN ISLAMIC SCHOOL']]
    //     const ws = XLSX.utils.aoa_to_sheet(dataHeader)
    //     const merge = []
    //     for (let i = 0; i < 3; i++) {
    //       merge.push({ s: { r: i, c: 0 }, e: { r: i, c: 5 } })
    //       ws['A' + (i + 1)].s = {
    //         font: {
    //           name: 'Arial',
    //           sz: 12,
    //           color: { rgb: '#FF000000' },
    //           bold: true,
    //           italic: false,
    //           underline: false,
    //         },
    //         alignment: {
    //           horizontal: 'center',
    //         },
    //       }
    //     }
    //     const wscols = [
    //       { wch: 20 }, // "characters"
    //       { wch: 40 },
    //       { wch: 20 },
    //       { wch: 25 },
    //       { wch: 20 },
    //       { wch: 20 },
    //     ]
    //     ws['!cols'] = wscols
    //     ws['!merges'] = merge
    //     const allBorder = {
    //       top: { style: 'thin' },
    //       bottom: { style: 'thin' },
    //       left: { style: 'thin' },
    //       right: { style: 'thin' },
    //     }
    //     const dataTableNilai = []
    //     const headerTable = {
    //       A: 'NIS', B: 'Nama', C: 'Nilai Essay', D: 'Nilai Pilihan Ganda', E: 'Total Nilai CBT', F: 'Nilai Final',
    //     }
    //     dataTableNilai.push(headerTable)
    //     data.cbt.cbt_attendances.forEach((muridCbtAttendance) => {
    //       if (muridCbtAttendance.murid.id_kelas === data.kelasTingkat[i].id) {
    //         const dataTable = {
    //           A: muridCbtAttendance.murid.nis,
    //           B: muridCbtAttendance.murid.nama,
    //           C: muridCbtAttendance.nilai_essay || '-',
    //           D: muridCbtAttendance.nilai_pg || '-',
    //           E: muridCbtAttendance.total_nilai || '-',
    //           F: muridCbtAttendance.new_nilai || '-',
    //         }
    //         dataTableNilai.push(dataTable)
    //       }
    //     })
    //     XLSX.utils.sheet_add_json(ws, dataTableNilai, { skipHeader: true, origin: 'A5' })
    //     for (const key in ws) {
    //       if (key[0] !== '!') {
    //         const col = parseInt(key.split('').slice(1).join(''))
    //         if (col > 3) {
    //           if (col === 5) {
    //             ws[key].s = { // karena tabel dimulai di baris ke 5
    //               font: {
    //                 name: 'Arial',
    //                 sz: 10,
    //                 color: { rgb: '#FF000000' },
    //                 // bold: false,
    //                 // italic: false,
    //                 // underline: false,
    //               },
    //               alignment: {
    //                 horizontal: 'center',
    //               },
    //               border: allBorder,
    //             }
    //           } else {
    //             ws[key].s = { // karena tabel dimulai di baris ke 5
    //               font: {
    //                 name: 'Arial',
    //                 sz: 10,
    //                 color: { rgb: '#FF000000' },
    //                 // bold: false,
    //                 // italic: false,
    //                 // underline: false,
    //               },
    //               alignment: {
    //                 horizontal: key[0] === 'B' ? null : 'center',
    //               },
    //               border: allBorder,
    //             }
    //           }
    //         }
    //       }
    //     }
    //     XLSX.utils.book_append_sheet(wb, ws, kelas)
    //   }
    //   /* generate file and send to client */
    //   // XLSX.writeFile(wb, `Daftar Nilai CBT ${this.detailCbt.tipe} Mapel ${this.detailCbt.mapel}.xlsx`, { cellStyles: true })
    //   const title = `Daftar Nilai CBT ${this.detailCbt.tipe} Mapel ${this.detailCbt.mapel} Semester ${this.detailCbt.tahun_ajaran.semester} Tahun ${this.detailCbt.tahun_ajaran.tahun}.xlsx`
    //   var wbout = XLSXStyle.write(wb, { bookType: 'xlsx', bookSST: true, type: 'binary' }, title)
    //   FileSaver.saveAs(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }), title)
    // },
    handleSelectClass(value) {
      this.selectedClass = value
      this.fetchDataCbtTable({
        idKelas: value,
      })
    },
    handleSelectView(e) {
      const delay = t => new Promise(resolve => setTimeout(resolve, t))
      if (e.target.value === 'Analytics') {
        this.disableExport = true
        this.textRadio2 = 'Please Wait...'
        delay(100).then(() => {
          this.textRadio2 = 'Multiple Choice Analysis'
          this.selectView = e.target.value
          this.disableExport = false
        })
      } else {
        this.textRadio1 = 'Please Wait...'
        delay(100).then(() => {
          this.textRadio1 = 'Grade Result'
          this.selectView = e.target.value
        })
      }
    },
    handleTable(pagination, filters, sorter) {
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      if (sorter.field === 'Name') {
        this.sortBy = 'nama'
      } else if (sorter.field === 'EssayGrade') {
        this.sortBy = 'nilai_essay'
      } else if (sorter.field === 'MultipleChoiceGrade') {
        this.sortBy = 'nilai_pg'
      } else if (sorter.field === 'FinalGrade') {
        this.sortBy = 'new_nilai'
      } else if (sorter.field === 'Attendance') {
        this.sortBy = 'status_attendance'
      }
      if (sorter.order === 'ascend') {
        this.order = 'ASC'
      } else if (sorter.order === 'descend') {
        this.order = 'DESC'
      } else {
        this.order = 'ASC'
      }
      // console.log(this.order)
      // console.log(this.sortBy)
      // console.log(this.selectedClass)
      this.fetchDataCbtTable({
        page: pagination.current,
        idKelas: this.selectedClass,
        order: this.order,
        sortBy: this.sortBy,
      })
    },
    handleGradeIndex(idSoal) {
      const answers = [...this.dataDrawer.EssayAnswer]
      const target = answers.filter(item => idSoal === item.id_soal)[0]
      return target.grade
    },
    handleSlider(value, idSoal) {
      // console.log(value, idSoal)
      const answers = [...this.dataDrawer.EssayAnswer]
      const target = answers.filter(item => idSoal === item.id_soal)[0]
      target.grade = value
      // console.log(answers)
      // this.gradeEssay = event
    },
    showDrawer(id) {
      const newData = [...this.dataTable]
      const target = newData.filter(item => id === item.key)[0]
      this.dataDrawer = target
      this.visibleDrawer = true
      // console.log(this.dataDrawer)
    },
    onCloseDrawer() {
      this.visibleDrawer = false
    },
    numberQuestion(index) {
      return ++index
    },
    answer(idSoal, jawabanMurid) {
      const target = jawabanMurid.filter(item => idSoal === item.id_soal)[0]
      if (target) {
        return target.jawaban
      } else {
        return ''
      }
    },
    refreshTable() {
      this.loadingRefresh = true
      this.fetchDataCbtTable({
        idKelas: this.selectedClass,
        page: this.pagination.current,
      })
    },
    fetchDataCbt() {
      const idCbt = this.$route.params.id
      const idGuru = this.user.id
      this.isLoading = true
      this.$store.dispatch('cbt/FETCH_CBT_BY_ID', { idCbt, idGuru })
        .then(res => {
          this.isLoading = false
          this.detailCbt = { ...res.cbt }
          this.listPengawas = res.listPengawas
          this.detailCbt.mapel = this.detailCbt.mata_pelajaran.nama
          if (this.detailCbt.waktu_ujian_dibuka && this.detailCbt.waktu_ujian_ditutup) {
            this.detailCbt.waktu_ujian = moment(this.detailCbt.waktu_ujian_dibuka).format('DD MMM YYYY HH:mm') + ' - ' + moment(this.detailCbt.waktu_ujian_ditutup).format('DD MMM YYYY HH:mm')
          }
        })
    },
    fetchDataCbtTable(params = { page: 1, order: 'ASC', sortBy: '', idKelas: '' }) {
      // console.log(params.sortBy)
      // console.log(params.order)
      const idCbt = this.$route.params.id
      this.loadingTable = true
      this.$store.dispatch('cbt/FETCH_CBT_ATTENDANCES_BY_ID', { idCbt, page: params.page, order: params.order, sortBy: params.sortBy, idKelas: params.idKelas })
        .then(res => {
          // console.log(res)
          this.totalMurid = res.totalMurid
          const kelas = res.kelas ? `${res.kelas.level.nama}-${res.kelas.simbol}` : '-'
          this.kelasCBT = kelas
          this.loadingTable = false
          this.loadingRefresh = false
          this.classes = res.kelasTingkat
          this.notesBeritaAcaraPerKelas = res.cbt.pengawas_cbts ? res.cbt.pengawas_cbts[0].notes_berita_acara : ''
          // console.log(this.classes)
          let no = 1
          const soalEssay = res.cbt.cbt_soals.filter(row => {
            return row.tipe_soal === 'Essay'
          })
          const soalEssayBaru = soalEssay.map(row => {
            return {
              id: row.id,
              tipe_soal: row.tipe_soal,
              nomor_soal: row.nomor_soal,
              soal: row.soal,
              jawaban: row.jawaban,
              kunci_jawaban: row.kunci_jawaban,
              bobot_soal: row.bobot_soal,
            }
          })

          const soalMultipleChoice = res.cbt.cbt_soals.filter(row => {
            return row.tipe_soal === 'Multiple Choice'
          })

          const soalColumn = soalMultipleChoice.map(row => {
            const rowMurid = res.cbt.cbt_attendances
            let counter = 0
            let counterCorrect = 0
            rowMurid.forEach(elMurid => {
              const jawaban = JSON.parse(elMurid.jawaban)
              if (jawaban) {
                jawaban.forEach(rowJawaban => {
                  if (row.id === rowJawaban.id_soal) {
                    counter++
                    if (row.kunci_jawaban.toLowerCase() === rowJawaban.jawaban) {
                      counterCorrect++
                    }
                  }
                })
              }
            })
            // const tingkatKesukaran = parseFloat(counterCorrect / counter).toFixed(1)
            const avgCorrect = parseInt((counterCorrect / counter) * 100)
            let el
            let tingkatKesukaran
            let penjelasan
            if (avgCorrect <= 100 && avgCorrect >= 75) {
              el = (<small class="text-success">{avgCorrect}%</small>)
              tingkatKesukaran = 'MD'
              penjelasan = 'MUDAH'
            } else if (avgCorrect <= 74 && avgCorrect >= 50) {
              el = (<small class="text-warning">{avgCorrect}%</small>)
              tingkatKesukaran = 'SD'
              penjelasan = 'SEDANG'
            } else if (avgCorrect <= 49 && avgCorrect >= 0) {
              el = (<small class="text-danger">{avgCorrect}%</small>)
              tingkatKesukaran = 'SK'
              penjelasan = 'SUKAR'
            } else {
              el = (<small>-</small>)
              tingkatKesukaran = '-'
            }
            const title = (
              <a-tooltip>
                <template slot="title">
                  Correct Answer : {row.kunci_jawaban}
                </template>
                <small>{no++}</small>
              </a-tooltip>
            )
            const tingkatKesukaranToolTip = (
              <a-tooltip>
                <template slot="title">
                  {penjelasan}
                </template>
                {tingkatKesukaran}
              </a-tooltip>
            )
            const percentageCorrect = (
              <a-tooltip>
                <template slot="title">
                  Percentage of Correct Answer
                </template>
                {el}
              </a-tooltip>
            )
            return {
              key: row.id,
              title: (<div>{title}</div>),
              align: 'center',
              children: [
                {
                  key: row.id,
                  title: (<span>{percentageCorrect}</span>),
                  align: 'center',
                  children: [
                    {
                      key: row.id,
                      title: (<small>{tingkatKesukaranToolTip}</small>),
                      dataIndex: 'Soal' + row.id,
                      align: 'center',
                      width: soalMultipleChoice.length > 5 ? 45 : 100,
                      scopedSlots: { customRender: 'Soal' },
                    },
                  ],
                },
              ],
            }
          })
          const dataTableAnalytics = res.cbt.cbt_attendances.map(rowMurid => {
            const jawabanMultipleChoiceMurid = {}
            let correct = 0
            let wrong = 0
            let filled = 0
            let totalfilled = 0
            soalMultipleChoice.forEach(el => {
              const jawaban = JSON.parse(rowMurid.jawaban)
              if (jawaban) {
                for (let i = 0; i < jawaban.length; i++) {
                  const rowJawaban = jawaban[i]
                  if (el.id === rowJawaban.id_soal) {
                    filled++
                    // console.log('Murid ' + rowMurid.id_murid + 'Jawaban no id' + el.id + ' ' + rowJawaban.jawaban + ' KJ ' + el.kunci_jawaban)
                    if (el.kunci_jawaban.toLowerCase() === rowJawaban.jawaban) {
                      correct++
                      jawabanMultipleChoiceMurid[`Soal${el.id}`] = `${rowJawaban.jawaban}|CORRECT`
                    } else {
                      wrong++
                      jawabanMultipleChoiceMurid[`Soal${el.id}`] = `${rowJawaban.jawaban ? rowJawaban.jawaban : '-'}|WRONG`
                    }
                    break
                  }

                  if (i === jawaban.length - 1 && el.id !== rowJawaban.id_soal) {
                    wrong++
                    jawabanMultipleChoiceMurid[`Soal${el.id}`] = 'HOLD'
                  }
                }
              } else {
                jawabanMultipleChoiceMurid[`Soal${el.id}`] = 'HOLD'
              }
            })
            totalfilled = (filled / soalMultipleChoice.length) * 100
            const murid = rowMurid.kelas_tahun_ajaran
            return {
              key: rowMurid.id,
              nis: murid.murid.nis,
              Name: murid.murid.nama,
              Class: murid.kela.level.nama + '-' + murid.kela.simbol,
              ...jawabanMultipleChoiceMurid,
              Correct: correct,
              Wrong: wrong,
              Filled: totalfilled,
            }
          })
          this.columnsAnalytics[1].children = soalColumn
          this.dataTableAnalytics = dataTableAnalytics
          this.dataTable = res.cbt.cbt_attendances.map((row, idx) => {
            const essayAnswerBundle = []
            soalEssayBaru.forEach(el => {
              const jawaban = JSON.parse(row.jawaban)
              if (jawaban) {
                const essayAnswer = jawaban.filter(rowJawaban => {
                  return rowJawaban.tipe_soal === 'Essay'
                })
                if (essayAnswer.length) {
                  for (let i = 0; i < essayAnswer.length; i++) {
                    const rowJawaban = essayAnswer[i]
                    if (el.id === rowJawaban.id_soal) {
                      essayAnswerBundle.push({
                        status: 'filled',
                        tipe_soal: 'Essay',
                        id_soal: essayAnswer[i].id_soal,
                        jawaban: essayAnswer[i].jawaban,
                        grade: 0,
                      })
                      break
                    }

                    if (i === essayAnswer.length - 1 && el.id !== rowJawaban.id_soal) {
                      essayAnswerBundle.push({
                        status: 'unfilled',
                        tipe_soal: 'Essay',
                        id_soal: el.id,
                        jawaban: '',
                        grade: 0,
                      })
                    }
                  }
                } else {
                  essayAnswerBundle.push({
                    status: 'unfilled',
                    tipe_soal: 'Essay',
                    id_soal: el.id,
                    jawaban: '',
                    grade: 0,
                  })
                }
              }
            })
            // const jawaban = row.jawaban ? JSON.parse(row.jawaban) : ''
            // let essayAnswer = ''
            // if (jawaban) {
            //   essayAnswer = jawaban.filter(rowJawaban => {
            //     return rowJawaban.tipe_soal === 'Essay'
            //   })
            //   essayAnswer = essayAnswer.map(rowMap => {
            //     return {
            //       id_soal: rowMap.id_soal,
            //       grade: 0,
            //     }
            //   })
            // }
            // console.log('NIS ' + row.murid.nis + ' TOTAL ' + row.total_nilai)
            const murid = row.kelas_tahun_ajaran
            const isRemedial = idx === 0
            return {
              key: row.id,
              nis: murid.murid.nis,
              Name: murid.murid.nama,
              Class: murid.kela.level.nama + '-' + murid.kela.simbol,
              TotalCbt: row.total_nilai,
              FinalGrade: row.new_nilai,
              Attendance: row.status_attendance != null ? 'present' : 'absent',
              EssayGrade: row.nilai_essay,
              MultipleChoiceGrade: row.nilai_pg,
              Soal: soalEssayBaru,
              MultipleChoiceAnswer: row.jawaban ? JSON.parse(row.jawaban) : '',
              EssayAnswer: essayAnswerBundle,
              isRemedial,
              // EssayAnswer: essayAnswer,
              // ...jawabanMultipleChoiceMurid,
            }
          })
          // console.log(this.dataTable)
          const pagination = { ...this.pagination }
          pagination.total = res.totalMurid
          pagination.pageSize = 25
          this.pagination = pagination
        })
    },
    fecthIsSlot() {
      const idGuru = this.user.id
      const idCbt = this.$route.params.id
      this.$store.dispatch('cbt/FETCH_CBT_SLOT', { idCbt, idGuru })
        .then(res => {
          if (res === 'TRUE_GURU_MAPEL') {
            this.statusSlot = true
          } else {
            this.statusSlot = false
          }
        })
    },
    cekAnswer(soal, jawabanMurid) {
      // console.log(jawabanMurid)
      // jawabanMurid.forEach(el => {
      // console.log(row.nomor_soal === soal.nomor_soal)
      // if (soal.nomor_soal === el.nomor_soal) {
      // console.log('Soal ke'+soal.nomor_soal+' = ' + el.jawaban)
      //     if (soal.kunci_jawaban === row.jawaban) {
      //       this.pgState.answer = row.jawaban
      //       this.pgState.color = '#41B883'
      //     } else {
      //       this.pgState.answer = row.jawaban
      //       this.pgState.color = '#F91E22'
      //     }
      //   }
      // })
      // console.log(this.pgState)
      return this.pgState
    },
    updateGrade(idAttendances) {
      this.loadingUpdateGrade = true
      const essayAnswer = this.dataDrawer.EssayAnswer
      // console.log(essayAnswer[0])
      let no = 1
      const hasil = essayAnswer.map(row => {
        let style = ''
        if (row.grade === 0) {
          // console.log('Masuk')
          style = 'text-danger'
        }
        return (
          <p>{no++}. <span class={style}>{row.grade}</span></p>
        )
      })
      if (essayAnswer.length) {
        this.$confirm({
          title: 'Warning',
          content: (
            <div>
              <p>Are you sure want to grade this essay answer? you cannot change the grade after you submit this grade.</p>
              <p>Essay Grade : </p>
              <span>{hasil}</span>
            </div>
          ),
          onOk: () => {
            // const idCbt = this.$route.params.id
            const page = this.pagination.current ? this.pagination.current : 1
            const idKelas = this.selectedClass ? this.selectedClass : ''
            const idGuru = this.user.id
            this.$store.dispatch('cbt/PUT_ESSAY_ANSWER', { id: idAttendances, idGuru, nilaiBundle: this.dataDrawer.EssayAnswer })
              .then(res => {
                if (res) {
                  this.loadingUpdateGrade = false
                  this.gradeEssay = 0
                  this.visibleDrawer = false
                  this.fetchDataCbtTable({
                    page,
                    idKelas,
                  })
                  this.$notification.success({
                    message: 'Success.',
                    description: 'Essay grade has been updated',
                  })
                }
              })
          },
          onCancel: () => {
            this.loadingUpdateGrade = false
          },
          centered: true,
          icon: 'warning',
          okType: 'primary',
          okText: 'Submit Now',
        })
      } else {
        this.$notification.error({
          message: 'Error.',
          description: 'You cannot sumbitting grade with no answer in this student',
        })
        this.loadingUpdateGrade = false
      }
    },
    async daftarHadirToPDF() {
      if (this.selectedClass !== '') {
        let countPresent = 0
        let countAbsent = 0
        for (let i = 0; i < this.dataTable.length; i++) {
          if (this.dataTable[i].Attendance === 'present') {
            countPresent += 1
          } else if (this.dataTable[i].Attendance === 'absent') {
            countAbsent += 1
          }
        }

        const dataTablePDF = this.dataTable.map(row => {
          return [
            row.Name,
            row.nis,
            row.Attendance,
          ]
        })

        const tipe = this.detailCbt.tipe
        const tahun = this.detailCbt.tahun_ajaran.tahun
        const semester = this.detailCbt.tahun_ajaran.semester.toUpperCase()
        const mapel = this.detailCbt.mapel
        const kelas = this.kelasCBT
        const splitSimbol = await kelas.split('-')
        const simbol = splitSimbol[1]
        const index = simbol - 1
        const namaPengawas = this.listPengawas[index]?.nama
        const nikPengawas = this.listPengawas[index]?.nik
        const tingkat = this.detailCbt.tingkat
        const ujianStart = this.waktuUjian.awal
        const ujianEnd = this.waktuUjian.akhir
        const totalPeserta = this.totalMurid
        let detailTipe = ''
        if (tipe === 'PH') {
          detailTipe = 'PENILAIAN HARIAN'
        } else if (tipe === 'PTS') {
          detailTipe = 'PENILAIAN TENGAH SEMESTER'
        } else if (tipe === 'PAS') {
          detailTipe = 'PENILAIAN AKHIR SEMESTER'
        } else if (tipe === 'PAT') {
          detailTipe = 'PENILAIAN AKHIR TAHUN'
        }
        const doc = new JsPDF('p', 'pt', 'a4')

        doc.setFont('Helvetica', 'italic')
        doc.setFontSize(16)
        doc.text(`DAFTAR HADIR PESERTA ${detailTipe} (${tipe})\nTAHUN PELAJARAN ${tahun} SEMESTER ${semester}`, 60, 50)
        doc.setFont('Helvetica', 'normal')
        doc.setFontSize(12)
        doc.text(`Mata Pelajaran: ${mapel}`, 40, 100)
        doc.text(`Kelas: ${kelas === '-' ? tingkat : kelas}`, 40, 115)
        doc.text(`Tanggal: ${moment(ujianStart).format('DD MMMM YYYY')}`, 400, 100)
        doc.text(`Waktu: ${moment(ujianStart).format('HH:mm') + ' - ' + moment(ujianEnd).format('HH:mm')}`, 400, 115)

        doc.autoTable({
          margin: { top: 130 },
          head: [['Nama Peserta', 'NIS', 'Status Attendance']],
          body: dataTablePDF,
        })

        doc.setFontSize(10)
        doc.text(`Jumlah peserta yang harus hadir: ${totalPeserta}`, 40, 760)
        doc.text(`Jumlah peserta yang hadir: ${countPresent}`, 40, 780)
        doc.text(`Jumlah peserta yang tidak hadir: ${countAbsent}`, 40, 800)
        doc.setFont('Helvetica', 'bold')
        doc.setFontSize(12)
        doc.text('PENGAWAS', 400, 760)
        doc.setFont('Helvetica', 'normal')
        doc.setFontSize(10)
        doc.text(`Nama: ${namaPengawas}`, 400, 780)
        doc.text(`NIK: ${nikPengawas}`, 400, 800)

        doc.save('DAFTAR_KEHADIRAN.pdf')
      } else {
        this.$notification.error({
          message: 'Error.',
          description: 'You should select the class.',
        })
      }
    },
    async exportBeritaAcara() {
      if (this.selectedClass !== '') {
        let countPresent = 0
        let countAbsent = 0
        for (let i = 0; i < this.dataTable.length; i++) {
          if (this.dataTable[i].Attendance === 'present') {
            countPresent += 1
          } else if (this.dataTable[i].Attendance === 'absent') {
            countAbsent += 1
          }
        }

        // const semester = this.detailCbt.tahun_ajaran.semester.toUpperCase()
        const kelas = this.kelasCBT
        const splitSimbol = await kelas.split('-')
        const simbol = splitSimbol[1]
        const index = simbol - 1
        // const namaPengawas = this.listPengawas[index].nama
        // const nikPengawas = this.listPengawas[index].nik
        // const tingkat = this.detailCbt.tingkat
        const totalPeserta = this.totalMurid
        const tipe = this.detailCbt.tipe
        const tahun = this.detailCbt.tahun_ajaran.tahun
        const mapel = this.detailCbt.mapel
        const ujianStart = this.waktuUjian.awal
        const ujianEnd = this.waktuUjian.akhir
        let namaPengawas = this.listPengawas[index].nama
        let nikPengawas = this.listPengawas[index].nik
        for (let x = 0; x < this.listPengawas.length; x++) {
          if (this.listPengawas[x].kelas === kelas) {
            namaPengawas = this.listPengawas[x].nama
            nikPengawas = this.listPengawas[x].nik
            break
          }
        }
        // const data = await this.$store.dispatch('cbt/FETCH_CBT_ATTENDANCES_BY_ID', {
        //   idCbt: this.$route.params.id,
        //   page: 'all',
        // })
        let detailTipe = ''
        if (tipe === 'PH') {
          detailTipe = 'PENILAIAN HARIAN'
        } else if (tipe === 'PTS') {
          detailTipe = 'PENILAIAN TENGAH SEMESTER'
        } else if (tipe === 'PAS') {
          detailTipe = 'PENILAIAN AKHIR SEMESTER'
        } else if (tipe === 'PAT') {
          detailTipe = 'PENILAIAN AKHIR TAHUN'
        }
        const hari = moment(ujianStart).format('dddd')
        let detailHari = ''
        if (hari === 'Monday') {
          detailHari = 'Senin'
        } else if (hari === 'Tuesday') {
          detailHari = 'Selasa'
        } else if (hari === 'Wednesday') {
          detailHari = 'Rabu'
        } else if (hari === 'Thursday') {
          detailHari = 'Kamis'
        } else if (hari === 'Friday') {
          detailHari = 'Jumat'
        } else if (hari === 'Saturday') {
          detailHari = 'Sabtu'
        } else if (hari === 'Sunday') {
          detailHari = 'Minggu'
        } else {
          detailHari = null
        }

        const obj = {
          kelas,
          inputBeritaAcaraRoot: this.notesBeritaAcaraPerKelas === null || this.notesBeritaAcaraPerKelas === undefined ? 'lancar' : this.notesBeritaAcaraPerKelas,
          tipe,
          tahun,
          mapel,
          ujianStart,
          ujianEnd,
          durasi: this.detailCbt.durasi,
          namaPengawas,
          nikPengawas,
          countPresent,
          countAbsent,
          totalPeserta,
          detailTipe,
          detailHari,
        }

        this.dataPrint = obj
        this.showPrint = true
        setTimeout(() => {
          this.isPrint = true
        }, 2000)
        // console.log(obj)
      } else {
        this.$notification.error({
          message: 'Error.',
          description: 'You should select the class.',
        })
      }
    },
  },
  computed: {
    user() { return this.$store.state.user.user },
    isDesktop() {
      // console.log(!this.$store.getters.isMobile && !this.$store.getters.isTablet)
      return !this.$store.getters.isMobile && !this.$store.getters.isTablet
    },
    waktuUjian() {
      const daftarKelas = [...this.classes]
      if (!this.detailCbt.waktu_ujian) {
        if (!this.selectedClass && daftarKelas.length) {
          const allClass = daftarKelas.sort((a, b) => a.pengawas_cbts[0]?.waktu_ujian_dibuka - b.pengawas_cbts[0]?.waktu_ujian_dibuka)
          const awal = allClass[0].pengawas_cbts[0]?.waktu_ujian_dibuka
          const akhir = allClass[allClass.length - 1].pengawas_cbts[0]?.waktu_ujian_ditutup
          const waktuAwal = moment(awal, 'YYYY-MM-DD HH:mm').format('DD MMM YYYY HH:mm')
          const waktuAkhir = moment(akhir, 'YYYY-MM-DD HH:mm').format('DD MMM YYYY HH:mm')
          return {
            str: `${waktuAwal} - ${waktuAkhir}`,
            awal,
            akhir,
          }
        }
        const kelas = this.classes.find(dat => dat.id === this.selectedClass)
        if (kelas) {
          const awal = kelas.pengawas_cbts[0]?.waktu_ujian_dibuka
          const akhir = kelas.pengawas_cbts[0]?.waktu_ujian_ditutup
          const waktuAwal = moment(awal, 'YYYY-MM-DD HH:mm').format('DD MMM YYYY HH:mm')
          const waktuAkhir = moment(akhir, 'YYYY-MM-DD HH:mm').format('DD MMM YYYY HH:mm')
          return {
            str: `${waktuAwal} - ${waktuAkhir}`,
            awal,
            akhir,
          }
        }
      }
      return {
        str: this.detailCbt.waktu_ujian,
        awal: this.detailCbt.waktu_ujian_dibuka,
        akhir: this.detailCbt.waktu_ujian_ditutup,
      }
    },
  },
  created() {
    this.fetchDataCbt()
    this.fetchDataCbtTable()
    this.fecthIsSlot()
  },
  mounted() {
    let arrayOfRef = []
    const soal = this.$refs.soal
    const jawaban = this.$refs.jawaban
    if (soal || jawaban) {
      if (soal) {
        if (Array.isArray(soal)) arrayOfRef = arrayOfRef.concat(soal)
        else arrayOfRef.push(soal)
      }

      if (jawaban) {
        if (Array.isArray(jawaban)) arrayOfRef = arrayOfRef.concat(jawaban)
        else arrayOfRef.push(jawaban)
      }

      renderMathMl(arrayOfRef)
    }
  },
}
</script>

<style lang="scss">
.modal-notes {
  .ant-table-fixed-left {
    @media (max-width: 426px) {
      width: 50%;
      overflow-x: auto !important;
    }
  }
  .ant-table-scroll {
    .ant-table-body .ant-table-fixed colgroup col:first-child {
      @media (max-width: 426px) {
        width: 12rem !important;
        min-width: 12rem !important;
        overflow-x: hidden;
      }
    }
    .ant-table-header .ant-table-fixed colgroup col:first-child {
      @media (max-width: 426px) {
        width: 12rem !important;
        min-width: 12rem !important;
      }
    }
  }
}
.resultTeacher {
  .coverDetail {
    width: 100%;
    min-height: 145px;
    background-color: var(--kit-color-primary);
    border-radius: 10px;
    padding: 20px 40px;
    color: #ffffff;
  }

  h2 {
    color: #ffffff;
  }

  .contentDetail {
    width: 100%;
    min-height: 20vh;
    background-color: white;
    padding: 40px;
    z-index: 11;
    margin-top: -10px;
    border-radius: 10px;
    box-shadow: 0 0 10px #0419301a;
    margin-left: 1px;
    .detail-slot {
      padding: 10px 0;
    }

    .tableGradeCbt {
      .ant-spin-nested-loading .ant-spin-container .ant-table {
        overflow-x: auto;
        table {
          @media (max-width: 769px) {
            width: auto;
          }
        }
      }
    }

    .multipleChoiceAnalysis {
      .ant-table-fixed-left {
        @media (max-width: 426px) {
          width: 50%;
          overflow-x: auto !important;
        }
      }
      .ant-table-scroll {
        .ant-table-body .ant-table-fixed colgroup col:first-child {
          @media (max-width: 426px) {
            width: 12rem !important;
            min-width: 12rem !important;
            overflow-x: hidden;
          }
        }
        .ant-table-header .ant-table-fixed colgroup col:first-child {
          @media (max-width: 426px) {
            width: 12rem !important;
            min-width: 12rem !important;
          }
        }
      }
    }
  }
}
</style>
